// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyCGTSdJW7E3crpAu46r8M321jCf-WTvrCo",
  authDomain: "tma-waitlist-2.firebaseapp.com",
  projectId: "tma-waitlist-2",
  storageBucket: "tma-waitlist-2.appspot.com",
  messagingSenderId: "376509872131",
  appId: "1:376509872131:web:d2ce17e3c792561028b636"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);