import React, { useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import Accordion from "../Accordion";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const Section = styled.section`
  min-height: 100vh;
  height: auto;
  width: 100vw;
  background-color: ${(props) => props.theme.text};
  color: ${(props) => props.theme.body};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;
  width: 80%;
  color: ${(props) => props.theme.body};
  line-height: 1.2;
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.body};
  width: fit-content;

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 75%;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  align-content: center;

  @media (max-width: 64em) {
    width: 80%;
  }

  @media (max-width: 48em) {
    width: 90%;
    flex-direction: column;

    & > *:last-child {
      & > *:first-child {
        margin-top: 0;
      }
    }
  }
`;

const Box = styled.div`
  width: 45%;

  @media (max-width: 64em) {
    width: 90%;
    align-self: center;
  }
`;

const Faq = () => {
  const ref = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    let element = ref.current;

    ScrollTrigger.create({
      trigger: element,
      start: "top top",
      end: "bottom top",
      pin: true,
      pinSpacing: false,
      scrub: true,
    });

    return () => {
      ScrollTrigger.killAll();
    };
  }, []);

  return (
    <Section ref={ref} id="faq">
      <Title>Faq</Title>

      <Container>
        <Box>
          <Accordion title="What makes ACampus.ai different from traditional learning platforms?">
          ACampus.ai stands out with its adaptive learning paths, real-time doubt resolution, and interactive content,
         tailoring education to individual needs for a more engaging and effective learning experience.
          </Accordion>
          <Accordion title="How does the doubt resolution system work in real-time?">
          DoubtSync allows users to receive instant clarifications through text, audio, and image support.
           Post your questions, and our platform ensures timely and comprehensive answers to enhance your understanding.
          </Accordion>
          <Accordion title="Can I interact directly with educators and experts on ACampus.ai?">
          Absolutely! Our Q&A Nexus feature enables you to participate in exclusive live sessions,
           providing direct access to educators and experts for insights,
           clarifications, and a deeper understanding of complex concepts.
          </Accordion>
        </Box>
        <Box>
          <Accordion title="Is ACampus.ai suitable for all learning levels?">
          Yes, ACampus.ai is designed for learners of all levels. SmartPaths adapt to individual proficiency levels,
           ensuring that content is tailored to your current understanding 
           and learning speed.
          </Accordion>
          <Accordion title="How does ACampus.ai foster community engagement?">
          Our platform encourages community interaction. Share insights, collaborate with fellow learners, 
          and participate in a supportive environment where curiosity is celebrated, and knowledge is shared.
          </Accordion>
          <Accordion title="Can I access ACampus.ai on mobile devices?">
          Absolutely! ACampus.ai is designed to be accessible on various devices. Whether you prefer learning on your computer, 
          tablet, or smartphone, our platform provides a seamless and responsive experience.
          </Accordion>
        </Box>
      </Container>
    </Section>
  );
};

export default Faq;
