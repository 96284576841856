import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import './TypeWriterText.css';
import { db } from '../utils/fireConfig';
import { addDoc, collection, getDocs } from "firebase/firestore";
import ConfettiComponent from "./Confetti";
import axios from "axios";



const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  width: 80%;
  color: ${(props) => props.theme.text};
  align-self: flex-start;
  line-height: 1.2;
  margin-bottom: 0.5rem;

  span {
    text-transform: uppercase;
    font-family: "Akaya Telivigala", cursive;
  }

  .text-1 {
    color: blue;
  }

  .text-2 {
    color: orange;
  }

  .text-3 {
    color: red;
  }

  @media (max-width: 70em) {
    font-size: ${(props) => props.theme.fontxl};
  }

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;
  }

  @media (max-width: 40em) {
    width: 90%;
  }
`;

const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.fontlg};
  text-transform: capitalize;
  color: ${(props) => `rgba(${props.theme.textRgba}, 0.6)`};
  font-weight: 600;
  margin-bottom: 1rem;
  width: 80%;
  align-self: flex-start;

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const ButtonContainer = styled.div`
  width: 200px;
  height: 80px;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  margin-left: auto; /* This will push the container to the right, aligning it with the right edge of its containing element */
  margin-right: auto; /* This will push the container to the left, aligning it with the left edge of its containing element */

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;
  }
`;

const Btn = styled.button`
  display: inline-block;
  background-color: ${(props) => props.theme.text};
  color: ${(props) => props.theme.body};
  outline: none;
  border: none;
  font-size: ${(props) => props.theme.fontsm};
  padding: 1.5rem 3rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;

  &:hover {
    transform: scale(0.9);
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: 2px solid ${(props) => props.theme.text};
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: all 0.2s ease;
  }

  &:hover::after {
    transform: translate(-50%, -50%) scale(1);
    padding: 0.3rem;
  }
`;
const TypeWriterText = () => {
  const [email, setEmail] = useState('');
  const userCollectionRef = collection(db, 'users');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const getUsers = async () => {
      try {
        const data = await getDocs(userCollectionRef);
        // console.log(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    }
    getUsers();
  }, [])

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  }

  const sendEmail = async () => {
    
      
  
      const payload = {
        "to": email,
        "subject": "Welcome to Acampus - You're on the Waitlist! 🚀",
        "htmlContent": "<p>Thank you for registering with Acampus, your gateway to personalized learning! 🌟</p><br><p>You're now officially on the waitlist. We'll notify you promptly once we launch. But hey, want to be an early explorer? Join our beta program by filling up this quick <a href='https://forms.gle/mozrTXFEt444V7ZCA' target='_blank'>Google Form</a>.</p><br><p>As a token of our appreciation for joining us on this exciting journey, we've prepared a <b>special reward</b> for you. Get ready for an enriching learning experience with Acampus!</p><br><p>Connect with us on social media:</p><a href='https://twitter.com/acampus_ai' target='_blank'><img src='https://img.freepik.com/free-vector/new-2023-twitter-logo-x-icon-design_1017-45418.jpg?size=338&ext=jpg&ga=GA1.1.1448711260.1706572800&semt=ais' alt='Twitter'width='48px' >     </a><a href='https://www.instagram.com/acampus.ai/' target='_blank'><img src='https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png' alt='Instagram'width='48px'>     </a><a href='https://www.linkedin.com/company/acampus' target='_blank'><img src='https://static-00.iconduck.com/assets.00/linkedin-icon-1024x1024-net2o24e.png' alt='LinkedIn' width='48px'>     </a>"

      };
  
      console.log('Sending email...');
      console.log(payload);
      axios.post('https://confirmation-api.onrender.com/send-email', payload)
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error.response.data);
        });
  
      // const response = await fetch(apiUrl, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(payload),
      // });
  
    //   if (response.ok) {
    //     console.log('Email sent successfully!');
    //   } else {
    //     console.error('Failed to send email:', response.statusText);
    //   }
    // } catch (error) {
    //   console.error('Error sending email:', error);
    // }
    
  };
  
  
  
  
  

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      setError('Invalid Email');
      return;
    } else {
      try {
        await addDoc(userCollectionRef, {
          email: email
        });
        setEmail('');
        setSent(true);
        await sendEmail();
      } catch (error) {
        console.error('Error registering user:', error);
        setError('Error registering user');
      }
    }
  }

  return (
    <>
      {sent && <ConfettiComponent />}

      <Title>
        Ignite Your Learning Odyssey!
        <Typewriter
          options={{
            autoStart: true,
            loop: true,
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString('<span class="text-1">Test with AI.</span>')
              .pauseFor(2000)
              .deleteAll()
              .typeString('<span class="text-2">Revise with AI.</span>')
              .pauseFor(2000)
              .deleteAll()
              .typeString('<span class="text-3">Learn with AI!</span>')
              .pauseFor(2000)
              .deleteAll()
              .start();
          }}
        />
      </Title>

      <SubTitle>Signup, and we'll send you a Gift 😉</SubTitle>

      <div className="input-container">
      <div>
        <input className="input-box" type="email" value={email} onChange={handleEmail} placeholder="Enter Your Email" />
      </div>
      <div>
        <button className="register-btn" onClick={handleRegister}>Register</button>
      </div>
      </div>

      <div className="thank">
        {sent && <p>Thank you for registering!</p>}
      </div>
    </>
  );
};

export default TypeWriterText;